 <template>
  <v-btn-toggle
    v-model="computedScope"
    dense
    borderless
    group
    color="primary"
    mandatory
  >
    <v-btn
      x-small
      value="local"
      @click="changeScope('local')"
    >
      <icon-tooltip
        small
        :icon-name="'i.Link'"
        :tooltip-text="tooltipLink"
      />
    </v-btn>
    <v-btn
      x-small
      value="global"
      @click="changeScope('global')"
    >
      <icon-tooltip
        small
        :icon-name="'i.LinkOff'"
        :tooltip-text="tooltipUnlink"
      />
    </v-btn>
  </v-btn-toggle>
</template>

<script>

export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      dataScope: this.scope,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  computed: {
    computedScope: {
      get () {
        return this.dataScope
      },
      set (v) {
        this.dataScope = v
        this.dataDocumentEmit()
      }
    }

  },
  props: {
    scope: String,
    tooltipLink: String,
    tooltipUnlink: String
  },
  methods: {
    changeScope (scope) {
      this.computedScope = scope
    },
    emitDocument () {
      if (this.scope !== this.dataScope) {
        this.$emit('update:scope', this.dataScope)
      }
    }
  },
  watch: {
    scope: {
      handler (v) {
        this.computedScope = v
      },
      immediate: true
    }
  }
}
</script>

<style lang="stylus" scoped></style>
